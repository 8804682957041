<template>
  <v-app>
    <div
      style="height: 100%;display: flex;justify-content: center;align-items: center;padding: 10px"
      :style="{backgroundColor: this.$wikaz.getInstance().theme.colors.backgroundlogin}"
    >
      <fx-formulaire-generique
        v-if="!reponse"
        style="height: 550px"
        :url-ressource="'api/wikaz/inscription/create' + ($route.params.slug ? ('?slug=' + $route.params.slug) : '')"
        @on-submit="f_validation"
      />
      <v-card
        v-else
        class="elevation-8"
        style="width: 400px"
      >
        <v-toolbar
          color="#456173"
          dense
        >
          <v-toolbar-title style="color: white">
            {{ reponse.titre }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>{{ reponse.message }}</v-card-text>
      </v-card>
    </div>
  </v-app>
</template>
<script>
export default {
    name: 'VxInscription',
    data () {
        return {
            reponse: null,
            // inscription: true,
        }
    },
    methods: {
        f_validation (data) {
            if (data.mdp1 === data.mdp2) {
                data.slug = this.$route.params.slug
                this.$wikaz.request.post('api/wikaz/inscription?client_id=' + this.$wikaz.getInstance().app.client_id, data).then((data) => {
                    if (data.code && data.code === 'info') {
                        this.reponse = data
                        // this.inscription = false
                    } else {
                        this.$wikaz.outils.notification.showAuto(data)
                    }
                })
            }
        },
    },
}
</script>